import Repository from "./repository"

const resource = "admin";

export default {
    async resetScouts() {
        let endpoint = `${resource}/reset`;
        let response = await Repository.post(endpoint, {});
        return response;
    },
    async updateStadistics(){
        let endpoint = `${resource}/update-stadistics`;
        let response = await Repository.post(endpoint, {});
        return response;
    },
    async getClub() {
        let endpoint = `${resource}/club`;
        let response = await Repository.get(endpoint);
        return response;
    }
}