import AdminRepository from "./admin.repository";
import IdentityRepository from "./identity.repository";
import ScoutRepository from "./scout.repository";
import PlayerRepository from "./player.repository";
import TeamRepository from "./team.repository";
import LeagueRepository from "./league.repository";
import RecordRepository from "./record.repository";
import MatchRepository from "./match.repository";
import SnapshotRepository from "./snapshot.repository";
import ShareRepository from "./share.repository";
import MetricRepository from "./metric.repository";

export const RepositoryFactory = {
    admin: AdminRepository,
    identity: IdentityRepository,
    scout: ScoutRepository,
    player: PlayerRepository,
    team: TeamRepository,
    league: LeagueRepository,
    record: RecordRepository,
    match: MatchRepository,
    snapshot: SnapshotRepository,
    share: ShareRepository,
    metric: MetricRepository
}